// Contract addresses: https://discord.com/channels/861728723991527464/896870883468136469

const contracts = {
    // main
    UniswapV2Factory: "0x9014B937069918bd319f80e8B3BB4A2cf6FAA5F7",
    UniswapV2Router02: "0x24ad62502d1C652Cc7684081169D04896aC20f30",
    JewelToken: "0x72Cb10C6bfA5624dD07Ef608027E366bd690048F",
    JewelLPToken: "0xEaB84868f6c8569E14263a5326ECd62F5328a70f",
    // xJEWEL: "0xA9cE83507D872C5e1273E745aBcfDa849DAA654F",
    Bank: "0xA9cE83507D872C5e1273E745aBcfDa849DAA654F",
    Banker: "0x3685Ec75Ea531424Bbe67dB11e07013ABeB95f1e",
    MasterGardener: "0xDB30643c71aC9e2122cA0341ED77d09D5f99F924",
    Airdrop: "0xa678d193fEcC677e137a00FEFb43a9ccffA53210",
    Profiles: "0xabD4741948374b1f5DD5Dd7599AC1f85A34cAcDD",
    HeroSale: "0x5F753dcDf9b1AD9AabC1346614D1f4746fd6Ce5C",
    GaiasTears: "0x24eA0D436d3c2602fbfEfBe6a16bBc304C963D04",
    DFKGold: "0x3a4EDcf3312f44EF027acfd8c21382a5259936e7",
    Vendor: "0xe53BF78F8b99B6d356F93F41aFB9951168cca2c6",

    // hero and summoning 
    SaleAuction: "0x13a65B9F8039E2c032Bc022171Dc05B30c3f2892",
    HeroSummoningUpgradeable: "0x65DEA93f7b886c33A78c10343267DD39727778c2",
    Crystals: "0xa2D001C829328aa06a2DB2740c05ceE1bFA3c6bb",
    MeditationCircle: "0x0594D86b2923076a2316EaEA4E1Ca286dAA142C1",

    // questing
    QuestCoreV2: "0x5100Bd31b822371108A0f63DCFb6594b9919Eaf4",
    Gardening: "0xe4154B6E5D240507F9699C730a496790A722DF19",
    Mining: "0x569E6a4c2e3aF31B337Be00657B4C040C828Dd73",
    Foraging: "0x3132c76acF2217646fB8391918D28a16bD8A8Ef4",
    Fishing: "0xE259e8386d38467f0E7fFEdB69c3c9C935dfaeFc",
    WishingWell: "0xF5Ff69f4aC4A851730668b93Fc408bC1C49Ef4CE",

    // plants
    Ambertaffy: "0x6e1bC01Cc52D165B357c42042cF608159A2B81c1",
    Darkweed: "0x68EA4640C5ce6cC0c9A1F17B7b882cB1cBEACcd7",
    Goldvein: "0x600541aD6Ce0a8b5dae68f086D46361534D20E80",
    Ragweed: "0x043F9bd9Bb17dFc90dE3D416422695Dd8fa44486",
    Redleaf: "0x094243DfABfBB3E6F71814618ace53f07362a84c",
    Rockroot: "0x6B10Ad6E3b99090De20bF9f95F960addC35eF3E2",
    SwiftThistle: "0xCdfFe898E687E941b124dfB7d24983266492eF1d",
    BlueStem: "0xAC5c49Ff7E813dE1947DC74bbb1720c353079ac9",
    Milkweed: "0xc0214b37FCD01511E6283Af5423CF24C96BB9808",

    // fish
    Bloater: "0x78aED65A2Cc40C7D8B0dF1554Da60b38AD351432",
    Ironscale: "0xe4Cfee5bF05CeF3418DA74CFB89727D8E4fEE9FA",
    Lanterneye: "0x8Bf4A0888451C6b5412bCaD3D9dA3DCf5c6CA7BE",
    Redgill: "0xc5891912718ccFFcC9732D1942cCD98d5934C2e1",
    Sailfish: "0xb80A07e13240C31ec6dc0B5D72Af79d461dA3A70",
    Shimmerskin: "0x372CaF681353758f985597A35266f7b330a2A44D",
    Silverfin: "0x2493cfDAcc0f9c07240B5B1C4BE08c62b8eEff69",

    // runes
    ShvasRune: "0x66F5BfD910cd83d3766c4B39d13730C911b2D286",

    // pets
    BluePetEgg: "0x9678518e04Fe02FB30b55e2D0e554E26306d0892",
    GreyPetEgg: "0x95d02C1Dc58F05A015275eB49E107137D9Ee81Dc",
    GoldenEgg: "0x9edb3Da18be4B03857f3d39F83e5C6AAD67bc148",
    GreenPetEgg: "0x6d605303e9Ac53C59A3Da1ecE36C9660c7A71da5",
    Spiderfruit: "0x19B9F05cdE7A61ab7aae5b0ed91aA62FF51CF881",
    YellowPetEgg: "0x3dB1fd0Ad479A46216919758144FD15A21C3e93c",

};

export default contracts;